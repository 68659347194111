import React from "react";
import styled, { css } from "styled-components";
import get from "lodash.get";

import Container from "../styles/Container";

const Wrapper = styled.div`
  padding-top: 37px;
  border-top: 1px solid ${(p) => p.theme.lightGrey};
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    padding-top: 50px;
  }
`;

const Heading = styled.h1`
  margin-bottom: 37px;
  font-size: 18px;
  line-height: 30px;
  text-transform: uppercase;
  text-align: center;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    margin-bottom: 50px;
    font-size: 30px;
    line-height: 42px;
  }
`;

const NetworksContainer = styled.div`
  @media (min-width: 600px) {
    display: flex;
    flex-wrap: wrap;
  }
`;

const Network = styled.div`
  margin-bottom: 37px;
  @media (min-width: 600px) {
    width: calc(50% - 20px);
    :nth-child(2n + 1) {
      margin-right: 20px;
    }
    :nth-child(2n) {
      margin-left: 20px;
    }
  }
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    margin-bottom: 50px;

    width: calc(25% - 14px);
    :nth-child(4n + 1) {
      margin-right: 14px;
      margin-left: 0px;
    }
    :nth-child(4n + 2) {
      margin-left: 5px;
      margin-right: 9px;
    }
    :nth-child(4n + 3) {
      margin-left: 9px;
      margin-right: 5px;
    }
    :nth-child(4n) {
      margin-left: 14px;
      margin-right: 0px;
    }

    ${(p) =>
      p.count === 3 &&
      css`
        margin-bottom: 50px;

        width: calc((100% / 3) - 14px);
        :nth-child(3n + 1) {
          margin-right: 14px;
          margin-left: 0px;
        }
        :nth-child(3n + 2) {
          margin-left: 7px;
          margin-right: 7px;
        }
        :nth-child(3n) {
          margin-left: 14px;
          margin-right: 0px;
        }
      `}
  }
`;

const LogoContainer = styled.div`
  /* padding: 40px; */
  padding-left: 40px;
  padding-right: 40px;
  background-color: ${(p) => p.theme.lightBlue};
  margin-bottom: 21px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (min-width: 600px) {
    height: 190px;
    padding-left: 20px;
    padding-right: 20px;
  }
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    height: 220px;
    margin-bottom: 26px;
  }
`;

const Logo = styled.img`
  width: 100%;
  object-fit: contain;
  max-width: 340px;
  max-height: 120px;
  @media (min-width: 600px) {
    max-width: 400px;
  }
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    max-width: 100%;
    max-height: 140px;
  }
`;

const Description = styled.p`
  font-size: 14px;
  line-height: 26px;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    font-size: 16px;
    line-height: 30px;
  }
`;

const LearnMore = styled.a`
  font-size: 14px;
  line-height: 26px;
  font-weight: 700;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    font-size: 16px;
    line-height: 30px;
  }
  :hover {
    text-decoration: underline;
  }
`;

const LogoList = ({ heading, items }) => {
  return (
    <Container>
      <Wrapper>
        {heading && <Heading>{heading}</Heading>}
        {items && (
          <NetworksContainer>
            {items.map((item, index) => {
              const logo = get(item, "logo1.url");
              const description = get(item, "description1");
              const link = get(item, "link.url");
              const linkText = get(item, "link_text") || "Visit Site";
              return (
                <Network count={items.length} key={index}>
                  {logo && (
                    <a href={link} target="_blank" rel="noopener noreferrer">
                      <LogoContainer>
                        <Logo src={logo} alt="" />
                      </LogoContainer>
                    </a>
                  )}
                  {description && (
                    <>
                      <Description>{description}</Description>
                      {link && (
                        <LearnMore
                          href={link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {linkText}
                        </LearnMore>
                      )}
                    </>
                  )}
                </Network>
              );
            })}
          </NetworksContainer>
        )}
      </Wrapper>
    </Container>
  );
};

export default LogoList;
