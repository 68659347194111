import React from "react";
import { graphql } from "gatsby";
import NetworksHero from "../../components/NetworksHero";
import IconBox from "../../components/IconBox";
import LogoList from "../../components/LogoList";
import ImageList from "../../components/ImageList";
import ViewNext from "../../components/ViewNext";
import HelmetInfo from "../../components/HelmetInfo";

const Network = ({ data }) => {
  const networks = data.allPrismicNetwork.nodes;

  let nextNetworkTitle = undefined;
  let nextNetworkUID = undefined;

  if (Array.isArray(networks)) {
    const index = networks.findIndex((network) => {
      return network.uid === data.prismicNetwork.uid;
    });
    if (index != -1) {
      const length = networks.length;
      if (length - 1 > index) {
        const nextNetwork = networks[index + 1];

        nextNetworkTitle = nextNetwork.data.title;
        nextNetworkUID = nextNetwork.uid;
      }
    }
  }

  return (
    <>
      <HelmetInfo page_data={data.prismicNetwork.data} />
      <NetworksHero
        image={data.prismicNetwork.data.hero_image}
        logo={data.prismicNetwork.data.logo}
        text={data.prismicNetwork.data.description}
        buttons={data.prismicNetwork.data.buttons}
        facebook_link={data.prismicNetwork.data.facebook_link}
        instagram_link={data.prismicNetwork.data.instagram_link}
        twitter_link={data.prismicNetwork.data.twitter_link}
        youtube_link={data.prismicNetwork.data.youtube_link}
        linkedin_link={data.prismicNetwork.data.linkedin_link}
      />
      {data.prismicNetwork.data.body &&
        data.prismicNetwork.data.body.map((slice, index) => {
          if (slice.__typename === "PrismicNetworkDataBodyInfobox") {
            return (
              <IconBox
                key={index}
                heading={slice.primary.heading}
                subheading={slice.primary.subheading}
                icons={slice.items}
              />
            );
          } else if (slice.__typename === "PrismicNetworkDataBodyLogolist") {
            return (
              <LogoList
                key={index}
                heading={slice.primary.heading}
                items={slice.items}
              />
            );
          } else if (slice.__typename === "PrismicNetworkDataBodyImagelist") {
            return (
              <ImageList
                key={index}
                heading={slice.primary.heading}
                items={slice.items}
              />
            );
          }
          return null;
        })}
      {nextNetworkTitle && nextNetworkUID ? (
        <ViewNext
          link={`/network/${nextNetworkUID}`}
          linkText={nextNetworkTitle}
          borderTop
        />
      ) : (
        <ViewNext
          link={`/distribution-and-production`}
          linkText="Distribution & Production"
          borderTop
        />
      )}
    </>
  );
};

export default Network;

export const query = graphql`
  query ($uid: String!) {
    prismicNetwork(uid: { eq: $uid }) {
      uid
      data {
        hero_image {
          alt
          copyright
          url
          gatsbyImageData
        }
        logo {
          alt
          copyright
          url
          gatsbyImageData
        }
        description {
          text
          richText
        }
        title
        buttons {
          button_text
          button_link {
            type
            url
          }
        }
        homepage_link {
          type
          url
        }
        facebook_link {
          type
          url
        }
        instagram_link {
          type
          url
        }
        twitter_link {
          type
          url
        }
        youtube_link {
          type
          url
        }
        linkedin_link {
          type
          url
        }
        body {
          __typename
          ... on PrismicNetworkDataBodyInfobox {
            primary {
              heading
              subheading
            }
            items {
              icon {
                url
                alt
              }
              icon_description
            }
          }
          ... on PrismicNetworkDataBodyLogolist {
            primary {
              heading
            }
            items {
              logo1 {
                url
                alt
              }
              description1
              link {
                url
                type
              }
              link_text
            }
          }
          ... on PrismicNetworkDataBodyImagelist {
            primary {
              heading
            }
            items {
              image {
                url
                alt
              }
              title1
              image {
                url
                alt
              }
              link {
                type
                url
              }
            }
          }
        }
      }
    }
    allPrismicNetwork {
      nodes {
        uid
        data {
          logo {
            url
            alt
          }
          title
          short_description
        }
      }
    }
  }
`;
